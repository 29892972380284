import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid } from '@material-ui/core';
import { Fade } from 'react-reveal';
import i18n from '../../../../locale';
import Button from '../../../../components/Button';
import SectionWrapper from '../../../../components/SectionWrapper';
import Typography from '../../../../components/Typography';
import FooterItem from './FooterItem';
import Image from './Image';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        savings0: imageSharp(fluid: {
          originalName: {eq: "Main-Savings-1.jpg"}
        }) {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        savings1: imageSharp(fluid: {
          originalName: {eq: "Main-Savings-2.jpg"}
        }) {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        savings2: imageSharp(fluid: {
          originalName: {eq: "Main-Savings-3.jpg"}
        }) {
          fluid(maxWidth: 1200, maxHeight: 741, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        narrow: imageSharp(fluid: {
          originalName: {eq: "Main-Savings-Narrow.jpg"}
        }) {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }        
      }
    `}
    render={data => (
      <SectionWrapper className={styles.container}>
        <Typography variant='h4'
          weight='bold'
          className={styles.titleContainer}>
          {i18n('Save your time and avoid unpleasant situations')}
        </Typography>
        <Typography variant='subtitle2'
          weight='light'
          className={styles.subtitleContainer}>
          {i18n('See how the company vehicles are being used')}          
        </Typography>
        <div className={clsx(
            styles.titleContainer,
            styles.buttonContainer
          )}>
          <Link to={`${i18n('_url:landing-fleet-why')}`}
            style={{ textDecoration: 'none'}}>
            <Button className={styles.button}>
              {i18n('Read more')}
            </Button>
          </Link>
        </div>
        <Grid item xs={12} md={12} className={clsx(
            styles.imageContainer,
            styles.imageContainerNarrow
          )}>
          <Fade bottom>
            <Img objectFit='contain'
              className={styles.image}
              fluid={data.narrow.fluid}/>
          </Fade>
        </Grid>
        <Grid item container xs={12} md={12}
          alignContent='center'
          justifyContent='center'
          className={clsx(
            styles.imageContainer,
            styles.imageContainerWide
          )}>
          <Image fluid={data.savings0.fluid}
            delay={250}/>
          <Image fluid={data.savings1.fluid}
            layout={{ md: 4 }}/>
          <Image fluid={data.savings2.fluid}
            delay={500}/>
        </Grid>
        <Grid container className={styles.footerContainer}>
          <FooterItem text={i18n('Define your own tags that pass the company\'s internal communication')}/>
          <FooterItem text={i18n('Communicate with employees while in the field')}/>
          <FooterItem text={i18n('Show customers that a job has been done to avoid discussions')}/>
        </Grid>
      </SectionWrapper>
    )}/>
);