import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Header from '../../../components/Header/navmap/landingFleet';
import sitemap from '../../../data/sitemap';
import i18n from '../../../locale';
import Hero from './Hero';
import Why from './Why';
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import Savings from './Savings';
import UsedBy from './UsedBy';
import Sponsors from './Sponsors';
import Advantages from './Advantages';
import CaseStudies from './CaseStudies';
import RoiFleet from './RoiFleet';

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.landingFleet._}
    header={Header}
    isLanding={'fleet'}
    url='_url:landing-fleet'
    homeLink={i18n('_url:landing-fleet')}>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Why/>
    <Advantages/>
    <Fleet/>
    <DriverApp/>
    <Savings/>
    <UsedBy/>
    <Sponsors/>
    <CaseStudies/>
    <RoiFleet/>
  </Layout>
);