import React from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { graphql, Link, StaticQuery } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import SectionWrapper from '../../../../components/SectionWrapper';
import Button from '../../../../components/Button';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: imageSharp(fluid: {originalName: {eq: "Fleet-Logo-Text.png"}}) {
          fluid(maxWidth: 206) {
            ...GatsbyImageSharpFluid
          }
        }
        companies: imageSharp(fluid: {originalName: {eq: "Fleet-Hero-Companies.png"}}) {
          fluid(maxWidth: 440) {
            ...GatsbyImageSharpFluid
          }
        }
        hero: imageSharp (fluid: {
          originalName: { eq: "Fleet-Hero-Image.png" }
        }) {
          fluid(maxWidth: 1101, maxHeight: 768, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ hero, logo, companies }) => (
      <SectionWrapper className={styles.container}>
        <Grid container xs={12} xl={12} item justifyContent='space-between'>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.heroImageContainerMobile}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='right top'
              className={styles.imageContainer}
              fluid={hero.fluid}
              alt={i18n('Fleet management solution for everyone within the company')}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} className={styles.overviewContainer}
            direction='column'
            justifyContent='center'>
            <Img imgStyle={{ objectFit: 'none' }}
              objectFit='none'
              objectPosition='left center'
              className={styles.imageLogo}
              fluid={logo.fluid}
              alt='OBI+ Fleet'/>
            <Typography
              variant='h1'
              color='dark'
              weight='bold'
              className={clsx(
                styles.title,
                styles.heroText
              )}>
              {i18n('Overview of your vehicles in the easiest way')}
            </Typography>
            <div className={styles.heroText}>
              <Link to={`${i18n('_url:landing-fleet-demo')}`}
                className={styles.heroText}>
                <Button className={styles.button}>
                  {i18n('Try it for free')}
                </Button>
              </Link>
            </div>
            <Typography
              variant='h4'
              color='dark'
              weight='light'
              className={clsx(
                styles.subtitle,
                styles.heroText
              )}>
              {i18n('Hundreds of companies already use Fleet by OBI+')}
            </Typography>
            <Img imgStyle={{ objectFit: 'none' }}
              objectFit='none'
              objectPosition='left center'
              className={styles.imageCompanies}
              fluid={companies.fluid}
              alt={i18n('Hundreds of companies already use Fleet by OBI+')}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}
            className={styles.heroImageContainerWide}>
            <Img imgStyle={{ objectFit: 'contain' }}
              objectFit='contain'
              objectPosition='right top'
              className={styles.imageContainer}
              fluid={hero.fluid}
              alt='OBI+ Fleet'/>
          </Grid>
        </Grid>
      </SectionWrapper>
    )
  }/>
);